import React, { useEffect } from "react";
import { getAuth, signInWithPopup, GoogleAuthProvider} from "firebase/auth";

const provider = new GoogleAuthProvider();
const signInWithGoogle = () => {
    const auth = getAuth();
    signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            // IdP data available using getAdditionalUserInfo(result)
            // ...
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
            console.log(errorMessage);
        });
}

export default function Login(props) {
    useEffect(() => {
        console.log("Signed in: " + props.signedIn)
    }, [props.signedIn])

    // Attach an event listener that listens for changes in auth state and updates the signedIn bool
    useEffect(() => {
        getAuth().onAuthStateChanged(user => {
            props.setSignedIn(!!user)
            window.user = user
        })
    }, [])
    return (
        <>
            <div className="">
                <section className="header relative items-center flex h-screen justify-center">
                    <div className="rounded-lg bg-zinc-800 shadow-lg p-10 object-center center">
                        <h2 className="font-semibold text-4xl text-lime-500 text-center">
                            IMG2CP
                        </h2>
                        <hr className="my-4 md:min-w-full border-zinc-700" />
                        <center>
                        <p className="mt-4 text-md leading-relaxed text-zinc-400 justify-center">
                            {/* implement random phrase here */}
                            Powered by OrigamiHub
                        </p>
                        </center>
                        <div className="mt-12 text-zinc-500">
                            Sign in with
                            <button
                                onClick={signInWithGoogle}
                                className="mx-4 bg-zinc-700 active:bg-zinc-600 text-zinc-400 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                                type="button">
                                <img alt="..." className="w-5 mr-1" src={require("../assets/imgs/google.svg").default} />
                                Google
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}